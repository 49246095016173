<template>
<div class="my-5">
    <b-container class="shlajsna">
      <b-overlay :show="showOverlay">
      <b-row class="mb-5">
        <b-col cols="12" md="9" class="pr-4">
          <h1>{{ myTitle | removeLat }}</h1>
          <p>
            <b-avatar v-if="mUser.logo" :src="mUser.logo" size="1.75rem"/> 
            <b-link :to="{name: 'UserMedia',  params: { lang: this.$root.lang.shortName, userId: mUser.hash }}">
              {{ mUser.enterpriseName | removeLat }} 
            </b-link>
              | {{ dateTime(m.createdAt, "medium") }}
          </p>

          <div v-html="myDesc"></div>

          <audio controls preload="metadata" oncontextmenu="return false;" :src="m.lowResUrl" @play="playing">
              <source :src="m.lowResUrl" :type="m.mimeType">
          </audio>

          <b-row class="metaData my-4" v-if="myMetaData">
            <b-col cols="12" md="4">
              <div><span>{{ $t('videos.author')}}:</span> {{ m.author }}</div>
              <div><span>{{ $t('videos.source')}}:</span> {{ mUser.enterpriseName }}</div>
              <div><span>{{ $t('videos.year')}}:</span> {{ m.year }}</div>
              <div><span>{{ $t('videos.uploaded')}}:</span> {{ dateTime(m.createdAt, "medium") }} </div>
            </b-col>
            <b-col cols="12" md="4">
              <div><span>{{ $t('users.country')}}:</span> {{ mUser.country }}</div>
              <div><span>{{ $t('videos.duration')}}:</span> {{ secondsToTime(myMetaData.format.duration) }}</div>
              <div><span>{{ $t('videos.codec')}}:</span> {{ myMetaData.format.format_long_name }}</div>
              <div><span>{{ $t('videos.size')}}:</span> {{ getFileSize(myMetaData.format.size) }}</div>
            </b-col>
            <b-col cols="12" md="4">
              <div><span>{{ $t('videos.bit-rate')}}:</span> {{ getBitRate(myMetaData.streams[0].bit_rate) }}</div>
            </b-col>
          </b-row>
           <CategoriesSection :categories="m.categories" />
        </b-col>

        <b-col cols="12" md="3" class="pl-4 aside align-self-center">
          <h2>{{ $t('videos.download' )}}</h2>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="selectedDownload" 
              :options="downloadOptions" 
              :aria-describedby="ariaDescribedby"
              name="radio-btn-stacked" 
              buttons 
              stacked 
              button-variant="light" block>

            </b-form-radio-group>
          </b-form-group>
          <b-button block variant="primary" @click="download" size="lg">{{ $t("audio.download-audio") }}</b-button>
        </b-col>
      </b-row>
      </b-overlay>

      <b-row  class="mt-4">
        <b-col cols="12">
          <h2>{{ $t('general.recently-added')}}</h2>
        </b-col>
        <b-col cols="12">
          <MediaAudioGrid :media="media" type="public" />
        </b-col>
      </b-row>
      <b-row v-if="loadMore === true" class="mb-4">
        <b-col  cols="12">
          <p class="text-center">
            <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
              {{ $t('general.load-more') }} ({{ (page + 1) + "/" + totalPages }})
            </b-button>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div> 
</template>

<script>
import { BAvatar, BFormRadioGroup } from 'bootstrap-vue';
import CategoriesSection from '@/components/util/CategoriesSection.vue'
import MediaAudioGrid from '@/components/util/MediaAudioGrid.vue'
export default {
  name : "SingleAudio",
  components : { MediaAudioGrid, BAvatar, BFormRadioGroup, CategoriesSection},
  data() {
    return {
        m : {},
        mUser: {},
        media: [],
        showOverlay: true,
        selectedDownload: 'fullres',
        downloadOptions: [
            { text: this.$t("audio.originalAudio"), value: 'fullres' },
            { text: this.$t("audio.previewAudio"), value: 'lowres' }
        ],
        myMetaData:  null,
        played: false,
        loadMore: false,
        page: 0,
        totalPages: 0
    }
  },
  computed: {
    myTitle: function() {
        if (this.$root.lang.shortName === 'en') {
            return this.m.titleEn;
        } else {
            return this.m.title;
        }
    },
    myDesc: function() {
        if (this.$root.lang.shortName === 'en') {
            return this.m.descriptionEn;
        } else {
            return this.m.description;
        }
    },
    _lang: function() {
      return this.$root.lang
    }
  },
  async created() {
    this.setAudoMediaData()
    this.showOverlay = false;

    if (window.sessionStorage.getItem("singleAudioPagination")) {
      this.media = this.decodeJSON(window.sessionStorage.getItem("singleAudioPagination"));
      this.loadMore = window.sessionStorage.getItem("singleAudioPaginationHasNext");
      this.page = window.sessionStorage.getItem("singleAudioPaginationPage");
      this.totalPages = window.sessionStorage.getItem("singleAudioPaginationTotalPages");
    } else {
      this.setRecentAudioData();
    }
  },
  methods: {
    download: function() {
      var user = this.getAuthData();
      if (user === null) {
          this.$router.push({name : "Login"});
      } else {
          window.location = "/cms/api/media/download/" + this.m.id + "/" + this.selectedDownload;
      }
    },
    playing() {
      if (this.played === false) {
        this.played = true;
        this.$http.post('/api/media/ping/', this.m).then(r => {  
            // console.log(r);
            }).catch(e => { });
      }
    },
    loadMoreData() {
      this.page++;
      this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/audio/?page=' + this.page).then(r => {
        this.media = this.media.concat(r.data.content);
        this.loadMore = r.data.hasNext;
        window.sessionStorage.setItem("singleAudioPagination", this.encodeJSON(this.media));
        window.sessionStorage.setItem("singleAudioPaginationHasNext", r.data.hasNext);
        window.sessionStorage.setItem("singleAudioPaginationPage", this.page);
        window.sessionStorage.setItem("singleAudioPaginationTotalPages", r.data.totalPages);
      }).catch(e => {
        this.handleError(e);
      })
    },
    async setAudoMediaData() {
      await this.$http.get('/api/media/get/' +  this.$route.params.audioId + '/' + this.$root.lang.IANA).then(r => {
        //console.log(r);
        this.m = r.data;
        this.mUser = r.data.createdBy;
        this.myMetaData = JSON.parse(this.m.meta);
        //console.log(this.myMetaData);
        this.downloadOptions =  [
            { text: this.$t("audio.originalAudio"), value: 'fullres' },
            { text: this.$t("audio.previewAudio"), value: 'lowres' }
        ]

      }).catch(error => {
          this.handleError(error);
      })
    },
    async setRecentAudioData() {
      await this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/audio/?page=0').then(r => {
        this.media = r.data.content;
        this.loadMore = r.data.hasNext;
        this.totalPages = r.data.totalPages;
      }).catch(e => {
        this.handleError(e);
      })
    }
  },
  metaInfo: function() {
    return {
      title: this.m.title,
      description: this.m.description
    }
  },
  watch:{
    _lang : async function() {
      this.setAudoMediaData();
      this.setRecentAudioData();
    }
  }
}
</script>

<style></style>
